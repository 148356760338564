import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';

import {
  SiteBuilderModule,
  StyledHeading,
  StyledParagraph,
  StyledCallToAction,
  StyledAccordion,
  CallToActionConfigProps,
} from '../sharedComponents';

import { renderStyledElementStyles } from '../../helpers';
import { renderHTMLString } from '../../../../helpers';
import { LIGHT, DARK } from './constants';

/**
 * @description Override default theme using classes from accordion library
 */
const Wrapper = styled.div`
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.siteBuilderModules.accordionModule)}
  ${({ theme }) => renderStyledElementStyles(theme, theme.module.visualStyles)}
`;

const AccordionCallToAction = styled.div`
  margin-top: 20px;
`;

const AccordionModule = (props) => {
  const {
    section: {
      title,
      titleSize,
      subtitle,
      style,
      link,
      subsections,
      firstAccordionBlockOpen,
    },
  } = props;

  const themeConfig = useContext(ThemeContext);
  const visualStyles =
    themeConfig.siteBuilderModules.accordionModule.visualStyles[style];

  const ctaMode = () => {
    if (style === DARK) return 'color';
    if (style === LIGHT) return 'black';
    return 'none';
  };

  const accordionItems = subsections.map((section) => {
    return {
      title: section.title,
      titleSize: section.titleSize,
      content: (
        <>
          {section.body && renderHTMLString(section.body)}
          {section?.link && (
            <AccordionCallToAction>
              <StyledCallToAction
                className="accordion-link"
                type="link"
                ctaConfig={section.link}
              />
            </AccordionCallToAction>
          )}
        </>
      ),
    };
  });

  return (
    <SiteBuilderModule section={props.section} moduleTheme={{ visualStyles }}>
      <Wrapper>
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-9">
              <StyledHeading
                content={title}
                as={titleSize}
                visualStyles={visualStyles}
              />
              <StyledParagraph content={subtitle} visualStyles={visualStyles} />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <StyledAccordion
                visualStyles={visualStyles}
                items={accordionItems}
                firstAccordionBlockOpen={firstAccordionBlockOpen}
                scrollToActive
              />
            </div>
          </div>
          {link?.type && (
            <div className="row" data-testid="accordion-cta">
              <div className="col-xs-12">
                <StyledCallToAction
                  mode={ctaMode()}
                  ctaConfig={link}
                  look="accent"
                  visualStyles={visualStyles}
                />
              </div>
            </div>
          )}
        </div>
      </Wrapper>
    </SiteBuilderModule>
  );
};

AccordionModule.propTypes = {
  section: PropTypes.shape({
    style: PropTypes.oneOf([LIGHT, DARK]).isRequired,
    title: PropTypes.string,
    titleSize: PropTypes.string,
    text: PropTypes.string,
    link: CallToActionConfigProps,
    subsections: PropTypes.array,
  }).isRequired,
};

AccordionModule.defaultProps = {
  section: {
    title: '',
    link: {},
    style: '',
    subtitle: '',
    subsections: [],
  },
};

export default AccordionModule;
